/* global $, angular, document, massTimesApp, loadAdAgain */
//this used to be in app.js but moved it here for better load order/error avoidance
massTimesApp.run(['$rootScope', '$location', '$window', '$anchorScroll', '$state', '$transitions',
    function ($rootScope, $location, $window, $anchorScroll, $state, $transitions){
        $rootScope.$state = $state;
        $transitions.onSuccess({}, function($transitions){
            var toState = $transitions.$to();
            var fromState = $transitions.$from();

            // always set the title in browser to what's set in routes.js
            if(toState.data && toState.data.pageName && toState.data.pageName !== "MassTimes - Map") {
                $window.document.title = toState.data.pageName;
            }

            // //never fire here for map page
            // if (toState.name === 'map') return;
            // //never fire here when redirecting from bare URL
            // if (toState.redirectTo) return;
            // //never fire here if it's the same route
            // if (fromState && toState){
            //     if (fromState.url === toState.url) return;
            // }


            if (toState.name === 'default'){
                rotator.display();
            } else {
                // scroll to top when navigating to new URLS but not back/forward http://stackoverflow.com/a/29153678/1444948
                $anchorScroll();
            }

            // to disable display of donate footer on map screen
            // if (toState.name == 'map') {
            //     $('#donate-container').hide();
            // }
            // else {
            //     $('#donate-container').show();
            // }
        });
    }
]);
