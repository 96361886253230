
//look at url to see if we're PhoneGap
var urlVars = window.location.href.split('?');
var isPhonegap = (urlVars.length > 1 && urlVars[1].search('target=phonegap') != -1);
if (isPhonegap) {
    // PhoneGap application because we included ?target=phonegap in our the phonegap xml src=
    var cordovajs = 'assets/scripts/cordova.js';
    var cordovareadyjs = 'assets/scripts/cordova-ready.js';
    // simplified append to head of remote scripts with path composition
    appendScript(cordovajs);
    appendScript(cordovareadyjs);

} else {
    if(urlVars.length > 1 && urlVars[1].search('target=ios') != -1){
        createElement('style','#donate-container{margin-bottom: 20px};');
        createElement('style','#list-bottom{margin-bottom: 20px};');
    }
}

function appendScript(pathToScript) {
    var head = document.getElementsByTagName("head")[0];
    var js = document.createElement("script");
    js.type = "text/javascript";
    js.src = pathToScript;
    head.appendChild(js);
}
function createElement(_type,code) {
    let s = document.createElement(_type);
    s.appendChild(document.createTextNode(code));
    document.body.appendChild(s);
  }