/* global massTimesApp, navigator */
massTimesApp.factory('geoLocationFactory', ['$q', '$http', function ($q, $http)
{
    var latLong = null;
    return {
        getCurrentPosition: function (refresh)
        {
            var deferred = $q.defer();
            if (latLong === null || refresh) {
                if (navigator.geolocation){
                    navigator.geolocation.getCurrentPosition(function (pos)
                    {
                        latLong = { 'latitude': pos.coords.latitude, 'longitude': pos.coords.longitude };
                        deferred.resolve(latLong);
                    }, function (error)
                    {
                        latLong = null;
                        deferred.reject('Failed to get position from navigator.geolocation.');
                    }, { enableHighAccuracy: true, maximumAge: 30000, timeout: 15000 });
                } else {
                    deferred.reject('Failed to get position because navigator.geolocation does not exist.');
                }

            } else {
                deferred.resolve(latLong);
            }
            return deferred.promise;
        },
        geoCodeResult: function (destination)
        {
            var deferred = $q.defer();
            var search = encodeURIComponent(destination.description.text);
            var getString = 'https://geocode.arcgis.com/arcgis/rest/services/World/GeocodeServer/findAddressCandidates?f=json&maxLocations=2';
            getString += '&SingleLine='+search;
            getString +='&magicKey='+destination.description.magicKey;

            $http.get(getString)
                .then(function(result){
                    if (result.data.error) {
                        if(result.data.error.details.toString().toLowerCase().includes("magickey")) {
                            // try again without magic key
                            var getStringNoKey = 'https://geocode.arcgis.com/arcgis/rest/services/World/GeocodeServer/findAddressCandidates?f=json&maxLocations=2';
                            getStringNoKey += '&SingleLine='+search;
                            $http.get(getStringNoKey)
                                .then(function(result){
                                    if (result.data.error) {
                                        throw new Error('geocode API noMagic: ' + JSON.stringify(result.data.error));
                                    } else if(result.data && result.data.candidates && result.data.candidates.length)
                                        deferred.resolve(cleanCandidate(result.data.candidates[0]));
                                      else throw new Error('geocode API noMagic noData: ' + JSON.stringify(result));
                                }).catch(function (err) {
                                    throw new Error(err);
                                });
                        } else throw new Error('geocode API other: ' + JSON.stringify(result.data.error));
                    } else if(result.data && result.data.candidates && result.data.candidates.length)
                        deferred.resolve(cleanCandidate(result.data.candidates[0]));
                      else throw new Error('geocode API noData: ' + JSON.stringify(result));
                }).catch(function (err) {
                    deferred.reject('Failed to return geocode latlong data.');
                });

            return deferred.promise;
        }
    };
    function cleanCandidate (candidate) {
        if(typeof candidate.location.x === "number"){
            candidate.location.x = candidate.location.x ? candidate.location.x = parseFloat(candidate.location.x.toFixed(3)) : candidate.location.x;
            candidate.location.y = candidate.location.y ? candidate.location.y = parseFloat(candidate.location.y.toFixed(3)) : candidate.location.y;
        }
        return candidate;
    }
}]);
