// massTimesApp.config(['$routeProvider', '$locationProvider', function ($routeProvider, $locationProvider) {
//     $routeProvider.
//     when("/",
//         {   templateUrl: "components/home/home.html?v=1.1",
//             controller: 'HomeController',
//             title: 'MassTimes - Find Catholic worship times for Mass, Confession, Adoration and Holy Days'
//         }).
//     when("/about",
//         {   templateUrl: "components/about/about.html?v=1.0",
//             controller: 'AboutController',
//             title: 'MassTimes - About'
//         }).
//     when("/donate",
//         {   templateUrl: "components/donate/donate.html",
//             title: 'MassTimes - Donate'
//         }).
//     when("/map",
//         {   templateUrl: "components/map/map.html?v=1.1",
//             controller: 'MapController',
//             title: 'MassTimes - Map',
//             reloadOnSearch: false
//         }).
//     when("/volunteer",
//         {   templateUrl: "components/volunteer/volunteer.html",
//             title: 'MassTimes - Volunteer'
//         }).
//     otherwise(
//         {   templateUrl: "components/404/404.html",
//             title: 'MassTimes - 404 Not Found'
//         });
//     $locationProvider.html5Mode(true);
// }]);
massTimesApp.factory('timeoutHttpIntercept', ['$rootScope', function ($rootScope) {
    return {
      'request': function(config) {
        config.timeout = 1 * 90 * 1000;
                      // 1 minute or 60 seconds or 60,000 milliseconds
       return config; }
      };
    }]);
massTimesApp.config(['$httpProvider', '$stateProvider', '$urlRouterProvider', '$locationProvider', '$uiRouterProvider', '$compileProvider', 'cfpLoadingBarProvider', function ($httpProvider, $stateProvider, $urlRouterProvider, $locationProvider, $uiRouterProvider, $compileProvider, cfpLoadingBarProvider) {

    cfpLoadingBarProvider.includeSpinner = false;
    cfpLoadingBarProvider.parentSelector = '#loading-bar-container';

    // https://docs.angularjs.org/guide/production
    $compileProvider.debugInfoEnabled(false);
    $compileProvider.commentDirectivesEnabled(false);
    $compileProvider.cssClassDirectivesEnabled(false);

    $httpProvider.interceptors.push('timeoutHttpIntercept');
    var StickyStates = window['@uirouter/sticky-states'];
    $uiRouterProvider.plugin(StickyStates.StickyStatesPlugin);
    $locationProvider.html5Mode(true);
    $urlRouterProvider.when("", "/");
    $urlRouterProvider.otherwise("404");
    $stateProvider
    .state('default', {
        url: '/',
        templateUrl: 'components/home/home.html',
        controller: "HomeController",
        data: { pageName: 'MassTimes - Find Catholic worship times for Mass, Confession, Adoration and Holy Days', tabGroup: null }
    })
    .state('about', {
        url: "/about",
        templateUrl: 'components/about/about.html',
        controller: "AboutController",
        data: { pageName: 'MassTimes - About', tabGroup: null }
    })
    .state('map', {
        url: "/map",
        data: { pageName: 'MassTimes - Map', tabGroup: null },
        views: {
            'mapview': {
                templateUrl: 'components/map/map.html'
            }
        },
        sticky: true
    })
    .state('volunteer', {
        url: "/volunteer",
        templateUrl: 'components/volunteer/volunteer.html',
        // controller: "AboutController",
        data: { pageName: 'MassTimes - Volunteer', tabGroup: null }
    })
    .state('404', {
        url: "/404",
        templateUrl: 'components/404/404.html',
        data: { pageName: 'MassTimes - 404 Not Found', tabGroup: null }
    })
    ;
}]);