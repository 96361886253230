massTimesApp.filter('dayOfWeekFilter', function () {
    return function (items,DayOfWeek) {
        var filtered =[];
        for (var i = 0; i < items.length; i++) {
            var item=items[i];
            if (item.currentDay == DayOfWeek.name || item.currentDay == DayOfWeek.alias || DayOfWeek.name == 'All' || item.currentDay =='All') { 
                filtered.push(item);
            }
        }
        return filtered;
    };
});