/* global massTimesApp, clearTheCache, app, massTimesAppVersion, massTimesAppVersionNew, navigator, Connection, alert, location */
(function ()
{

    massTimesApp.controller('AboutController', ['$scope', function ($scope)
    {
        $scope.app = isApp != "Web";
        $scope.massTimesAppVersion = massTimesAppVersion != 'undefined' ? massTimesAppVersion : "";
        $scope.massTimesAppVersionNew = typeof massTimesAppVersionNew != 'undefined' ? massTimesAppVersionNew : "";

        $scope.resetAppFromAbout = function ()
        {
            if (!navigator.onLine) {
                alert("No Internet connection found. Please connect and try again.");
            } else {
                clearTheCache();
                alert("Hit OK to reset.");
                location.reload();
            }
        };

    }]);

})();
