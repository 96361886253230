massTimesApp.factory('userSettingsFactory', ['$q', function ($q)
{
    var settings = {
        sortDistance: false,
        distanceDisplayKm: false,
        timeDisplay24: false,
        setTimeToNow: false,
        loaded: false
    };
    return {
        checkFirstRun: function ()
        {
            const deferred = $q.defer();
            if (localforage){
                localforage.getItem('firstRun').then(function(value) {
                    if (!value) {
                        localforage.setItem('firstRun', new Date());
                        deferred.resolve(true);
                    } else {
                        deferred.resolve(false);
                    }
                }).catch(function(err) {
                    console.log(err);
                });
            }
            return deferred.promise;
        },
        loadSettings: function (refresh)
        {
            const deferred = $q.defer();
            if (!settings.loaded || refresh) {
                if (localforage){
                    localforage.iterate(function(value, key, iterationNumber) {
                        settings[key] = value;
                    }).then(function() {
                        settings.loaded = true;
                        deferred.resolve(settings);
                    }).catch(function(err) {
                        console.log(err);
                    });
                } else {
                    deferred.reject('Failed to get settings because localforage does not exist.');
                }
            } else {
                deferred.resolve(settings);
            }
            return deferred.promise;
        },
        // changeSetting: function (setting, value)
        // {
        //     localforage.setItem(setting, value);
        //     settings[setting] = value;
        // },
        saveAllIfChanged: function (newSettings)
        {
            for (let key in newSettings) {
                if (newSettings.hasOwnProperty(key) && settings[key] !== newSettings[key] && key !== 'loaded') {
                    localforage.setItem(key, newSettings[key]);
                }
            }
            settings = angular.copy(newSettings);
        }
    };
}]);
