( function () {
  massTimesApp.controller('HeaderController', ['$scope', '$rootScope', 'userSettingsFactory', '$location', 'geoLocationFactory', '$timeout',
    function headerController ($scope, $rootScope, userSettingsFactory, $location, geoLocationFactory, $timeout) {
      var hc = this;
      hc.location = $location;

      hc.searchDetails = '';
      hc.searchResult = $location.address;
      hc.hideSearchInHeader = false;
      hc.userSettingsOriginal = {};
      hc.userSettings = {};

      userSettingsFactory.checkFirstRun().then(function (firstRun) {
        if (firstRun) sa_event("newvisitor");
      }).catch(function (err) {
        // err
      });

      userSettingsFactory.loadSettings().then(function (userSettings) {
        hc.userSettingsOriginal = angular.copy(userSettings);
        hc.userSettings = angular.copy(userSettings);
      }).catch(function (err) {
        // err
      });

      $scope.$watch('hc.searchDetails', function(newValue, oldValue) {
        if(newValue) {
          geoLocationFactory.geoCodeResult(newValue)
            .then(function(found){
              var latitude = found.location.y;
              var longitude = found.location.x;
              hc.searchResult = found.address;
              $rootScope.$emit('updateLocation', {latitude: latitude, longitude: longitude, locationName: found.address});
            }).catch(function (err) {});
        }
      });

      $scope.$on('$locationChangeSuccess', function (event, toURL, fromURL) {
        if (toURL.indexOf('map') > -1) { // if we're on map page:
          hc.hideSearchInHeader = false;
          $timeout(function(){
            positionClearButton();
          }, 20);
        }
        else {
          hc.hideSearchInHeader = true;
          var navbarSearch = angular.element('#map-place-search-navbar_value');
          navbarSearch[0].value = "";
        }
        // simple page view:
        if (window.sa_pageview
          && toURL !== fromURL
          && (toURL.indexOf('map') == -1
            || hc.location.search()["SearchQueryTerm"]
          )) window.sa_pageview(window.location.pathname + window.location.search);
      });

      $(window).on('resize', function(event) {
        $timeout(function(){
          positionClearButton();
        }, 5);
      });

      function positionClearButton() {
        var navbarSearch = angular.element('#search-navbar-div');
        var clearSearch = angular.element('#searchclear-navbar');
        var xLocation = navbarSearch[0].offsetWidth - 26;
        clearSearch[0].style.marginLeft = xLocation + 'px';
      }
      positionClearButton();

      if(window.location.href.indexOf('map') > -1){
        hc.hideSearchInHeader = false;
      }
      else {
        hc.hideSearchInHeader = true;
      }

      $rootScope.$on('clearSearch', function(event, data) {
        $scope.$broadcast('angucomplete-alt:clearInput', 'map-place-search-navbar');
        var mapPlaceSearchValue = angular.element('#map-place-search-navbar_value');
        if(mapPlaceSearchValue && mapPlaceSearchValue.length){
            mapPlaceSearchValue = mapPlaceSearchValue[0];
            mapPlaceSearchValue.value = '';
            mapPlaceSearchValue.focus();
        }
      });

      hc.clearSearch = function() {
        $scope.$broadcast('angucomplete-alt:clearInput', 'map-place-search-navbar');
        $rootScope.$emit('clearSearch');
      };

      hc.saveSettings = function(isSave) {
        if (!isSave) {
          hc.userSettings = angular.copy(hc.userSettingsOriginal);
        } else {
          $rootScope.$emit('updateSettings', hc.userSettings);
          hc.userSettingsOriginal = angular.copy(hc.userSettings);
          userSettingsFactory.saveAllIfChanged(hc.userSettings);
        }
        $('#settingsModal').modal('hide');
      };

    }]);
// this is what is at top of file: (function () {
})();