/* global $, angular, document, window, localStorage, setTimeout */
var massTimesApp = null;
function startupAngularAppJs(){
    if (typeof angular === 'undefined') {
        // Recursively wait for angular to load
        setTimeout(startupAngularAppJs, 2000);
    } else {
        massTimesApp = angular.module('massTimesApp',
        ['ui.router',
        'angucomplete-alt',
        'angularResizable',
        'ui-leaflet',
        'nemLogging',
        'angular-loading-bar',
        ]);
    }
}
startupAngularAppJs();

// this is to force reload in app - the var must always match the one in version.js so it's not in a reload loop
massTimesAppVersion = 2.2;

function CheckVersionAndResetApp() {
    // these app versions are set in version.js and app.js = they need to match in the files saved to server, but if they differ, then reload.
    if(isApp != "Web" && typeof massTimesAppVersion !== 'undefined' && typeof massTimesAppVersionNew !== 'undefined'){
        if ((massTimesAppVersion < massTimesAppVersionNew) && navigator.onLine) {
            // add a 3 second delay before reloading
            setTimeout(function () {
                sa_event("reloadapp");
                clearTheCache();
                alert("There is an upgrade to the app available. Hit OK to reload.");
                location.reload();
            }, 2000);
        }
    }
}
function clearTheCache() {
    // Signal the "emptycache" event to the service worker
    if ('serviceWorker' in navigator) {
        navigator.serviceWorker.controller.postMessage('emptycache');
    }
}

function initJqueryItems(){
    if (typeof jQuery === 'undefined' || $('#top').length === 0){
        // Recursively wait for jQuery to load
        setTimeout(initJqueryItems, 2000);
    } else {
        //lazy load this in for version number:
        $.getScript("version.js");

        $(".navbar-collapse a").click(function () {
            $(".navbar-collapse").collapse("hide");
        });
        $("#logo-small").click(function () {
            $(".navbar-collapse").collapse("hide");
        });
        // this hides the index.html home-row-pre for rest of browser session
        var styleTag = $('<style>#home-row-pre { display: none; }</style>');
        $('html > head').append(styleTag);
        // simple page view:
        if (window.sa_pageview) window.sa_pageview(window.location.pathname + window.location.search);
    }
}
initJqueryItems();

function extractHttpModuleError(error) {
    // https://github.com/getsentry/sentry-javascript/issues/2292#issuecomment-1452306625
    if (error.error instanceof Error)
        return error.error;
    if (error.error instanceof ErrorEvent && error.error.message)
        return error.error.message;
    if (typeof error.error === 'string')
        return `Server returned code ${error.status} with body "${error.error}"`;
    if (typeof error.message === 'string')
        return error.message;
    return `Unknown Error in extractHttpModuleError`;
}

//directive to use to render unwatched items
//reference here: http://stackoverflow.com/questions/27553064/simple-directive-to-show-static-text
massTimesApp.directive('once', function () {

    return function (scope, element, attrs) {

        var parsed = scope.$eval(attrs.once);
        element.html(parsed);

    };

});

