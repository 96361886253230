/* global massTimesApp, getSearchBox, document, google, window, rotator */
(function () {

    massTimesApp.controller('HomeController', ['$scope', '$location', 'parishService', 'geoLocationFactory', function($scope, $location, parishService, geoLocationFactory) {
        //console.log("Home Page Controller reporting for duty.");
        $scope.parishService = parishService;
        $scope.$location = $location;
        $scope.latitude = 40.0000;
        $scope.longitude = -98.0000;

        //http://www.masstimes.org/search.html?lat=42.2980829&lng=-84.03788939999998&SearchQueryTerm=48118#0
        //http://www.masstimes.org/#/map?lat=42.2980829&lng=-84.03788939999998&SearchQueryTerm=48118
        //also handles
        //http://www.masstimes.org/search.html
        //http://www.masstimes.org/#/map
        var fullurl = $location.absUrl();
        if (fullurl.indexOf('search.html') !== -1) {
            if (fullurl.indexOf("?") !== -1) {
                var querystring = fullurl.substring(fullurl.indexOf("?"));
                var querystringwithouthash = querystring.substring(0, querystring.indexOf("#"));
                window.location.href = "/#/map" + querystringwithouthash;
            } else {
                window.location.href = "/#/map";
            }

        }

        $scope.clearSearch = function(){
            $scope.$broadcast('angucomplete-alt:clearInput', 'home-place-search');
            var homePlaceSearchValue = angular.element('#home-place-search_value');
            if(homePlaceSearchValue && homePlaceSearchValue.length){
                homePlaceSearchValue = homePlaceSearchValue[0];
                homePlaceSearchValue.value = '';
                homePlaceSearchValue.focus();
            }
        };

        //used with big logo link and the 'mass near me ->" link:
        $scope.findLocation = function ()
        {
            parishService.detectLocation = true;
        };

        //setup the ngAutocomplete
        $scope.searchOptions = {};
        $scope.searchOptions.watchEnter = true;
        $scope.searchDetails = '';
        $scope.$watch('searchDetails', function(newValue, oldValue) {
            if(newValue) {
                submitToMap($scope, $scope.searchDetails);
            }
            // else if (newValue !== "") {
            //     //first time page load newValue is ""
            //     $scope.searchresultmessage = "No results found. Please try another search.";
            // }
        });

        function submitToMap($scope, destination)
        {
            if(!destination) return;
            $scope.parishService.detectLocation = false;
            geoLocationFactory.geoCodeResult(destination).then(function(found){
                $scope.parishService.latitude = found.location.y;
                $scope.parishService.longitude = found.location.x;
                $scope.parishService.locationName = found.address;

                $scope.$location.search("lat", $scope.parishService.latitude);
                $scope.$location.search("lng", $scope.parishService.longitude);
                $scope.$location.search("SearchQueryTerm", $scope.parishService.locationName);
                $scope.$location.path('/map');
            }).catch(function (err) {});
        }

    }]);

})();
