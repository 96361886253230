
Date.prototype.getFormattedTime = function (military) {
    var hours = military ? this.getHours() : this.getHours() === 0 ? "12" : this.getHours() > 12 ? this.getHours() - 12 : this.getHours();
    //for leading zero: this.getHours() < 10 ? "0" + this.getHours() : this.getHours();
    var minutes = (this.getMinutes() < 10 ? "0" : "") + this.getMinutes();
    var ampm = military ? "" : " " + (this.getHours() < 12 ? "AM" : "PM");
    var formattedTime = hours + ":" + minutes + ampm;
    return formattedTime;
};

function toDate(dStr, format) {
    var now = new Date();
    if (format == "h:m") {
        now.setHours(dStr.substr(0, dStr.indexOf(":")));
        now.setMinutes(dStr.substr(dStr.indexOf(":") + 1, 2));
        now.setSeconds(0);
        return now;
    } else
        return "Invalid Format";
}
