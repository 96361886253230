// Sees if device and browser are compatable with apple pay
let applePayAvailable = false;
let didNotDonateTwoWeeks = true;

// check if window.localStorage is available and readable
var lStorage;
try {
	if(typeof(Storage) !== "undefined" &&
		typeof(window.localStorage) !== "undefined" &&
		window.localStorage.getItem("alargeteststringthatwillnotbeinlocalstorage") == null){
			lStorage = window.localStorage;
			didNotDonateTwoWeeks = (lStorage && ((lStorage.getItem("didDonate") == null) || (Date.parse(lStorage.getItem("2weeks")) < Date.parse(new Date()))));
		} else lStorage = false;
	applePayAvailable = window.ApplePaySession && ApplePaySession.supportsVersion(3) && ApplePaySession.canMakePayments();
} catch(e) {
	lStorage = false;
}

let applepaybutton = null;
let donateFooter = null;
async function getPayButtons(){
	if(!applePayAvailable) return false;
	applepaybutton = document.getElementById("apple-pay-button");
	donateFooter = document.getElementById("donate-footer");
	if (!donateFooter || !applepaybutton) {
		// Recursively wait for buttons to load
		await new Promise(resolve => setTimeout(resolve, 2000));
		return await getPayButtons();
	} else return true;
}
getPayButtons().then((buttonsLoaded) => {
	// set display of apple pay btn or blue donate btn:
	if(!buttonsLoaded) return;
	if (applePayAvailable && didNotDonateTwoWeeks) {
		var merchantId = window.location.hostname == "masstimes.org" ? "merchant.masstimes.org" : "merchant.test.masstimes.org";
		ApplePaySession.canMakePaymentsWithActiveCard(merchantId).then(function (canMakePayments) {
			if (canMakePayments) {
				applepaybutton ? applepaybutton.style.display = "inline-block" : null;
				donateFooter ? donateFooter.style.display = "none" : null;
			} else donateFooter ? donateFooter.style.display = "block" : null;
		});
	} else {
		applepaybutton ? applepaybutton.style.display = "none" : null;
		(donateFooter && didNotDonateTwoWeeks) ? donateFooter.style.display = "block" : null;
	}
});
// onclick from apple pay btn
function applePayPrice(){
	document.getElementById('apple-pay-donationAmt').style.display = "inline-block";
}

function closeAmount(){
	document.getElementById('apple-pay-donationAmt').style.display='none';
}

function closePopUp(){
	document.getElementById("apple-pay-message").style.display = "none";
}

// onclick for apple pay price select buttons:
function applePayButtonClicked(price){
	if(!price || price == "" || price === "" || price === "0"){
		document.getElementById('apple-pay-words').innerText = "Transaction Failed, Please enter valid dollar amount (1, 2, 50, etc.)";
		document.getElementById('apple-pay-message').style.display = "inline-block";
		document.getElementById('apple-pay-donationAmt').style.display = "none";
		return;
	}
	document.getElementById('apple-pay-donationAmt').style.display = "none";
	var request = {
	  countryCode: 'US',
	  currencyCode: 'USD',
	  supportedNetworks: ['visa', 'masterCard', 'amex', 'discover'],
	  merchantCapabilities: ['supports3DS','supportsCredit', 'supportsDebit'],
	  total: { label: 'Donation', amount: price },
	  requiredBillingContactFields: ['postalAddress']
	}

	var session = new ApplePaySession(3, request);
	session.begin();

	// Merchant Validation
	session.onvalidatemerchant = function (event) {
		var promise = performValidation();
		promise.then(function (merchantSession) {
			session.completeMerchantValidation(merchantSession);
		});
	}

	// server side lambda w Apple to validate Merchant:
	function performValidation() {
		return new Promise(function(resolve, reject) {
			var xhr = new XMLHttpRequest();
			xhr.onload = function() {
				var data = JSON.parse(this.responseText);
				resolve(data);
			};
			xhr.onerror = reject;
			xhr.open('POST', '/.netlify/functions/funct', false);
			xhr.send();
		});
	}

	session.onpaymentauthorized = function (event) {

		var promise = sendPaymentToken(event.payment);

		promise.then(function (success) {
			var status;
			if (success){
				status = ApplePaySession.STATUS_SUCCESS;
				let numWeeks = 2;
				let now = new Date();
				now.setDate(now.getDate() + numWeeks * 7);
				lStorage ? lStorage.setItem("2weeks", now) : null;
				lStorage ? lStorage.setItem("didDonate", new Date()) : null;
				applepaybutton ? applepaybutton.style.display = "none" : null;
				document.getElementById('apple-pay-words').innerText = "Thank you for your donation!";
				document.getElementById('apple-pay-message').style.display = "inline-block";
				sa_event("applepaysuccess");
			} else {
				document.getElementById('apple-pay-message').style.display = "inline-block";
				document.getElementById('apple-pay-words').innerText = "Transaction Failed.";
				status = ApplePaySession.STATUS_FAILURE;
			}
			session.completePayment(status);
		});
		// .catch((err) => {
		// 	//console.log("promise rejected", err);
		// });
	}
	// POST to our function to send ApplePay token to Auth.Net to charge the card:
	// https://developer.authorize.net/api/reference/index.html#mobile-in-app-transactions-create-an-apple-pay-transaction
	function sendPaymentToken(paymentToken) {
		return new Promise(function(resolve, reject) {
			let request = new XMLHttpRequest();
			request.onload = function() {
				var data = JSON.parse(this.responseText);
				if(data) {
					if(data.transactionResponse && data.transactionResponse.responseCode == 1) resolve(true);
					else resolve(false);
				} else reject;
			};
			request.open('POST', '/.netlify/functions/payment', false);
			paymentToken.amt = price;
			request.send(JSON.stringify(paymentToken));
		});
	}
}
